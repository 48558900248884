const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://k67t787b4l.execute-api.us-west-1.amazonaws.com/Prod';
const IS_DEV = process.env.NODE_ENV === 'prod';

// Development fallback data
const DEV_BOOKS = [
  {
    book_id: '1',
    title: 'The Call of Cthulhu',
    author: 'H.P. Lovecraft',
    genres: ['Horror', 'Cosmic Horror'],
    description: 'A terrifying tale of ancient cosmic entities.'
  },
  {
    book_id: '2',
    title: 'Frankenstein',
    author: 'Mary Shelley',
    genres: ['Gothic', 'Horror', 'Science Fiction'],
    description: 'A classic tale of creation and horror.'
  },
  {
    book_id: '3',
    title: 'Dracula',
    author: 'Bram Stoker',
    genres: ['Gothic', 'Horror', 'Vampire Fiction'],
    description: 'The seminal vampire horror novel.'
  },
  {
    book_id: '4',
    title: 'The Haunting of Hill House',
    author: 'Shirley Jackson',
    genres: ['Horror', 'Gothic', 'Psychological Horror'],
    description: 'A chilling haunted house story.'
  },
  {
    book_id: '5',
    title: 'House of Leaves',
    author: 'Mark Z. Danielewski',
    genres: ['Horror', 'Experimental', 'Psychological Horror'],
    description: 'A complex narrative about a mysterious house.'
  }
];

const DEV_GENRES = ['Horror', 'Gothic', 'Cosmic Horror', 'Psychological Horror', 'Science Fiction', 'Vampire Fiction', 'Experimental'];

const fetchWithHeaders = async (url, options = {}) => {
  if (IS_DEV) {
    // Simulate API delay in development
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // Return development data based on the endpoint
    if (url.includes('/collective-bookshelf')) {
      return DEV_BOOKS;
    }
    if (url.includes('/genres')) {
      return DEV_GENRES;
    }
    if (url.includes('/getBooks')) {
      const searchTerm = new URL(url).searchParams.get('title').toLowerCase();
      return DEV_BOOKS.filter(book => 
        book.title.toLowerCase().includes(searchTerm) ||
        book.author.toLowerCase().includes(searchTerm)
      );
    }
    return { message: 'Operation successful' };
  }

  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Origin': window.location.origin,
  };

  const response = await fetch(url, {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers,
    },
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({ error: 'Unknown error' }));
    throw new Error(errorData.error || 'Unknown error');
  }

  return response.json();
};

export const fetchCollectiveBooks = async () => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/collective-bookshelf`);
  } catch (error) {
    console.error('Error fetching collective books:', error);
    if (IS_DEV) return DEV_BOOKS;
    throw new Error(`Error fetching collective books: ${error.message}`);
  }
};

export const addBookToCollective = async (bookId, memberName) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/collective-bookshelf`, {
      method: 'POST',
      body: JSON.stringify({
        book_id: bookId,
        member_name: memberName,
      }),
    });
  } catch (error) {
    console.error('Error adding book to collective:', error);
    if (IS_DEV) return { message: 'Book added successfully (Development)' };
    throw new Error(`Error adding book to collective: ${error.message}`);
  }
};

export const searchBooks = async (title) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/getBooks?title=${encodeURIComponent(title)}`);
  } catch (error) {
    console.error('Error searching books:', error);
    if (IS_DEV) {
      return DEV_BOOKS.filter(book => 
        book.title.toLowerCase().includes(title.toLowerCase()) ||
        book.author.toLowerCase().includes(title.toLowerCase())
      );
    }
    throw new Error(`Error searching books: ${error.message}`);
  }
};

export const updateBook = async (bookId, bookData) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/book/${bookId}`, {
      method: 'PUT',
      body: JSON.stringify(bookData),
    });
  } catch (error) {
    console.error('Error updating book:', error);
    if (IS_DEV) return { ...bookData, message: 'Book updated successfully (Development)' };
    throw new Error(`Error updating book: ${error.message}`);
  }
};

export const deleteBook = async (bookId) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/book/${bookId}`, {
      method: 'DELETE',
    });
  } catch (error) {
    console.error('Error deleting book:', error);
    if (IS_DEV) return { message: 'Book deleted successfully (Development)' };
    throw new Error(`Error deleting book: ${error.message}`);
  }
};

export const fetchGenres = async () => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/genres`);
  } catch (error) {
    console.error('Error fetching genres:', error);
    if (IS_DEV) return DEV_GENRES;
    throw new Error(`Error fetching genres: ${error.message}`);
  }
};

export const submitQuiz = async (quizResponses) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/quiz`, {
      method: 'POST',
      body: JSON.stringify({ quizResponses }),
    });
  } catch (error) {
    console.error('Error submitting quiz:', error);
    if (IS_DEV) return { message: 'Quiz submitted successfully (Development)' };
    throw new Error(`Error submitting quiz: ${error.message}`);
  }
};
