import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '../button';
import { Input } from '../input';
import { searchBooks } from '../apiService';
import './bookshelfEnhanced.css'; // Updated to use enhanced styles

const AddBookModal = ({ onClose, onAdd }) => {
  const [bookTitle, setBookTitle] = useState('');
  const [memberName, setMemberName] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    if (!bookTitle.trim()) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const data = await searchBooks(bookTitle);
      setSearchResults(data);
    } catch (err) {
      setError('Failed to find book. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = async () => {
    if (!memberName.trim() || !searchResults) return;
    
    try {
      await onAdd(searchResults, memberName);
      onClose();
    } catch (err) {
      setError('Failed to add book. Please try again.');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="add-book-modal"
    >
      <h2 style={{ marginBottom: '20px' }}>Add Book to Collective</h2>
      
      <div style={{ marginBottom: '20px' }}>
        <Input
          type="text"
          placeholder="Enter book title..."
          value={bookTitle}
          onChange={(e) => setBookTitle(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <Button onClick={handleSearch} disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </Button>
      </div>

      {error && (
        <div style={{ color: '#ff6b6b', marginBottom: '20px' }}>{error}</div>
      )}

      {searchResults && (
        <>
          <div className="search-results">
            <h3>Found Book:</h3>
            <p>{searchResults.Title} by {searchResults.Authors.join(', ')}</p>
          </div>

          <Input
            type="text"
            placeholder="Your name..."
            value={memberName}
            onChange={(e) => setMemberName(e.target.value)}
            style={{ marginBottom: '20px' }}
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="secondary" onClick={onClose}>Cancel</Button>
            <Button 
              variant="default" 
              onClick={handleAdd}
              disabled={!memberName.trim()}
            >
              Add to Collective
            </Button>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default AddBookModal;
