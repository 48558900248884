import React from 'react';
import { motion } from 'framer-motion';

export const FloatingOrb = ({ delay, color = '#333' }) => (
  <motion.div
    className="floating-orb"
    style={{
      backgroundColor: color,
    }}
    animate={{
      y: ['0%', '-50%', '0%'],
      x: ['0%', '30%', '0%'],
      opacity: [0.4, 0.8, 0.4],
      scale: [1, 1.2, 1],
    }}
    transition={{
      duration: Math.random() * 5 + 5,
      repeat: Infinity,
      delay,
      ease: 'easeInOut',
    }}
  />
);

export const FogEffect = ({ position }) => (
  <motion.div
    className="fog-effect"
    style={{
      ...position,
    }}
    animate={{
      opacity: [0.1, 0.3, 0.1],
      scale: [1, 1.2, 1],
    }}
    transition={{
      duration: 8,
      repeat: Infinity,
      ease: 'easeInOut',
    }}
  />
);

export const SpiderWeb = ({ position, rotation = 0 }) => (
  <div
    className="spider-web"
    style={{
      transform: `rotate(${rotation}deg)`,
      ...position,
    }}
  >
    <svg width="120" height="120" viewBox="0 0 120 120">
      <g stroke="rgba(255,255,255,0.15)" fill="none" strokeWidth="0.5">
        <path d="M60,0 L60,120 M0,60 L120,60" />
        <path d="M0,0 L120,120 M120,0 L0,120" />
        <path d="M30,0 Q60,60 30,120" />
        <path d="M90,0 Q60,60 90,120" />
        <path d="M0,30 Q60,60 120,30" />
        <path d="M0,90 Q60,60 120,90" />
        <circle cx="60" cy="60" r="20" />
        <circle cx="60" cy="60" r="40" />
        <circle cx="60" cy="60" r="60" />
      </g>
    </svg>
  </div>
);

export const DeadTree = ({ side }) => (
  <div className={`dead-tree ${side}`}>
    <svg viewBox="0 0 100 200" width="100" height="200">
      <motion.path
        d="M50,200 L50,150 Q30,140 20,120 T0,80 M50,150 Q70,140 80,120 T100,80 M50,120 Q30,110 20,90 T10,50 M50,120 Q70,110 80,90 T90,50"
        stroke="rgba(30,30,30,0.8)"
        strokeWidth="4"
        fill="none"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      />
    </svg>
  </div>
);