// ProgressBar.jsx

import React from 'react';

const ProgressBar = ({ percentage }) => {
  return (
    <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
      <div
        className="h-full bg-gradient-to-r from-pink-500 to-pink-600 transition-all duration-300 ease-in-out"
        style={{ width: `${percentage}%` }}
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
        role="progressbar"
      ></div>
    </div>
  );
};

export default ProgressBar;
