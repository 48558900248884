// Question.jsx

import React, { useState } from 'react';

const Question = ({ question, onAnswer, selectedAnswers = [] }) => {
  const [selectedOption, setSelectedOption] = useState(selectedAnswers || (question.type === 'multiple' ? [] : ''));

  const handleOptionChange = (option) => {
    if (question.type === 'multiple') {
      let updatedSelections;
      if (selectedOption.includes(option)) {
        updatedSelections = selectedOption.filter((item) => item !== option);
      } else {
        updatedSelections = [...selectedOption, option];
      }
      setSelectedOption(updatedSelections);
      onAnswer(question.id, updatedSelections);
    } else {
      // For single-choice questions, just store the string value
      setSelectedOption(option);
      onAnswer(question.id, option);
    }
  };

  // Update local state when selectedAnswers prop changes
  React.useEffect(() => {
    setSelectedOption(selectedAnswers || (question.type === 'multiple' ? [] : ''));
  }, [selectedAnswers, question.type]);

  // Divide options into two columns
  const midIndex = Math.ceil(question.options.length / 2);
  const firstColumnOptions = question.options.slice(0, midIndex);
  const secondColumnOptions = question.options.slice(midIndex);

  return (
    <div className="question space-y-6">
      <h2 className="text-2xl font-bold text-pink-500 mb-6">{question.questionText}</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-4">
          {firstColumnOptions.map((option) => (
            <label
              key={option}
              className={`flex items-center space-x-3 p-3 rounded-lg cursor-pointer transition-all duration-200
                ${
                  (question.type === 'multiple' ? selectedOption.includes(option) : selectedOption === option)
                    ? 'bg-pink-600/20 border-pink-500'
                    : 'bg-gray-800/50 hover:bg-gray-700/50'
                }
                border-2 border-transparent hover:border-pink-500`}
            >
              <input
                type={question.type === 'multiple' ? 'checkbox' : 'radio'}
                name={question.id}
                value={option}
                checked={
                  question.type === 'multiple'
                    ? selectedOption.includes(option)
                    : selectedOption === option
                }
                onChange={() => handleOptionChange(option)}
                className="hidden"
              />
              <div className={`w-5 h-5 flex items-center justify-center border-2 rounded-md
                ${
                  (question.type === 'multiple' ? selectedOption.includes(option) : selectedOption === option)
                    ? 'border-pink-500 bg-pink-500'
                    : 'border-gray-400'
                }`}
              >
                {(question.type === 'multiple' ? selectedOption.includes(option) : selectedOption === option) && (
                  <svg className="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                  </svg>
                )}
              </div>
              <span className="text-white">{option}</span>
            </label>
          ))}
        </div>
        <div className="space-y-4">
          {secondColumnOptions.map((option) => (
            <label
              key={option}
              className={`flex items-center space-x-3 p-3 rounded-lg cursor-pointer transition-all duration-200
                ${
                  (question.type === 'multiple' ? selectedOption.includes(option) : selectedOption === option)
                    ? 'bg-pink-600/20 border-pink-500'
                    : 'bg-gray-800/50 hover:bg-gray-700/50'
                }
                border-2 border-transparent hover:border-pink-500`}
            >
              <input
                type={question.type === 'multiple' ? 'checkbox' : 'radio'}
                name={question.id}
                value={option}
                checked={
                  question.type === 'multiple'
                    ? selectedOption.includes(option)
                    : selectedOption === option
                }
                onChange={() => handleOptionChange(option)}
                className="hidden"
              />
              <div className={`w-5 h-5 flex items-center justify-center border-2 rounded-md
                ${
                  (question.type === 'multiple' ? selectedOption.includes(option) : selectedOption === option)
                    ? 'border-pink-500 bg-pink-500'
                    : 'border-gray-400'
                }`}
              >
                {(question.type === 'multiple' ? selectedOption.includes(option) : selectedOption === option) && (
                  <svg className="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                  </svg>
                )}
              </div>
              <span className="text-white">{option}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Question;
